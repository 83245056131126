import { range } from "lodash"
import React from "react"
import BackToHome from "../components/BackToHome"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import InfinityLogo from "../images/Infinity-logo.png"
import LunasolLogo from "../images/Lunasol-logo.png"
import getHTMLfromMD from "../utils/utils"
import './blog-post.css'






export default ({ data }) => {

  const post = data.markdownRemark
  const { frontmatter } = post
  const { title, subtitle, overtitle, image, pdf, pdfLabel, StyleClass } = frontmatter




  return (
    <Layout hideHamburger={true} className={`blog-postPage ${StyleClass}`}>
      <SEO previewImage={image} />
      <div className="blogPost-article">
        <div className='blogHeader'>
          <div className='logos-container'>
            <img src={LunasolLogo} alt={LunasolLogo} />
            <img src={InfinityLogo} alt={InfinityLogo} />
          </div>
          <div className='overtitle-container' dangerouslySetInnerHTML={{ __html: getHTMLfromMD(overtitle) }} />
          <div className='title-container' dangerouslySetInnerHTML={{ __html: getHTMLfromMD(title) }} />
          <div className='blogSubtitleContainer'>
            <div className='subtitle-container' dangerouslySetInnerHTML={{ __html: getHTMLfromMD(subtitle) }} />
          </div>
        </div>
        <div className='blogPostBody'>
          {
            range(5).map((index) => {

              const part = index + 1;


              return (
                (frontmatter[`ArticlePart${part}`] || frontmatter[`ArticlePart${part}`]) ? <div className='blogPostPart' key={index}>
                  {
                    (frontmatter[`ArticlePart${part}`]) ? <div className="ArticlePartArticle" dangerouslySetInnerHTML={{ __html: getHTMLfromMD(frontmatter[`ArticlePart${part}`]) }} /> : null
                  }
                  {
                    (frontmatter[`SeparatorPart${part}`]) ? <div className='ArticlePartSeparator' dangerouslySetInnerHTML={{ __html: getHTMLfromMD(frontmatter[`SeparatorPart${part}`]) }} /> : null
                  }
                </div> : null
              )
            })
          }
        </div>
      </div>
      {
        (pdf) ? <a href={pdf} target="_blank" rel="noopener noreferrer"><div className="pdfLabelContainer" dangerouslySetInnerHTML={{ __html: getHTMLfromMD(pdfLabel) }} /></a> : null
      }
      <BackToHome />
    </Layout>
  )
}



export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        overtitle
        subtitle
        StyleClass
        pdf
        pdfLabel
        ArticlePart1
        SeparatorPart1
        ArticlePart2
        SeparatorPart2
        ArticlePart3
        SeparatorPart3
        ArticlePart4
        SeparatorPart4
        ArticlePart5
        SeparatorPart5
        author {
          id
        }
        image
      }
    }
  }
`
